import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const Publicacao = () => {

    const navigate = useNavigate();

    const columns = useMemo(() => [

        {
            accessorKey: 'nome',
            header: 'Nome'
        },

        {
            accessorKey: 'visa',
            header: 'Visa',
        },

        {
            accessorKey: 'gvs_descricao',
            header: 'GVS',
        },

        {
            accessorKey: 'ibge_cidade',
            header: 'Municipío',
        },

        {
            accessorKey: 'status',
            header: 'Status',
        },

    ], []);



    const { publicacao_id } = useParams()

    const [numeroPublicacao, setNumeroPublicacao] = useState('')
    const [listaUsuariosPublicacao, setListaUsuariosPublicacao] = useState([])
    const [dataUsuariosPublicacao, setDataUsuariosPublicacao] = useState('')
    const [ad_new, setAd_new] = useState('')
    const [horaData, setHoraData] = useState('')
    const [dataPublicacao, setDataPublicacao] = useState('')

    const carregaPublicacao = () => {


        api.get(`publicacao/${publicacao_id}`).then((result) => {

            const dataPublicacao = result.data[0][0]
            const resultado = result.data[1]

            setDataUsuariosPublicacao(resultado)


            setAd_new(dataPublicacao.ad_new)
            setNumeroPublicacao(dataPublicacao.numeroPublicacao)
            setListaUsuariosPublicacao(dataUsuariosPublicacao)
            setDataPublicacao(dataPublicacao)


        }).catch((err) => {

            console.log(err.response)

        })


    }


    const pdfPublicacao = () => {
        api.get(`ExtraiPdf/${publicacao_id}`).then((result) => {
            console.log('We are here and the file is: ' + result.data.arquivo);

            const arquivoUrl = `https://apisecnr.saude.sp.gov.br/public/${result.data.arquivo}`;
            // Attempt to open the PDF in a new tab
            const newWindow = window.open(arquivoUrl, '_blank');

            if (newWindow) {
                newWindow.focus(); // Focus on the new window if successfully opened
            } else {
                alert('Failed to open PDF. Please allow pop-ups for this site.');
            }

        }).catch((err) => {
            console.error('Error retrieving the PDF:', err.response);
            alert('An error occurred while fetching the PDF. Please try again later.');
        });
    };





    useEffect(() => {

        if (publicacao_id) {

            carregaPublicacao()


        }

    }, [publicacao_id])



    return (

        <div>

            <div class="row">
                <div class="col-lg-12">

                    <div data-collapsed="0" class="card">

                        <div class="card-header">
                            <div class="card-title">

                                <h2 class="card-title">Ofício</h2>

                            </div>
                        </div>



                        <div class="card-body">

                            <div class="row">

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label">Número:</label>
                                        <input type="text" class="form-control" value={numeroPublicacao} disabled={true} />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label">Data/Hora:</label>
                                        <input type="text" class="form-control" value={ad_new} disabled={true} />
                                    </div>
                                </div>

                                <div class="col-lg-12">

                                    <br />

                                    <MaterialReactTable

                                        columns={columns}
                                        data={dataUsuariosPublicacao}
                                        localization={MRT_Localization_PT_BR}
                                        initialState={{ density: 'compact' }}
                                        muiTableHeadCellProps={{
                                            sx: {
                                                fontSize: {
                                                    xs: '8px',
                                                    sm: '9px',
                                                    md: '10px',
                                                    lg: '11px',
                                                    xl: '12px',
                                                },
                                            },
                                        }}
                                        muiTableBodyCellProps={{
                                            sx: {
                                                fontSize: {
                                                    xs: '8px',
                                                    sm: '9px',
                                                    md: '10px',
                                                    lg: '11px',
                                                    xl: '12px',
                                                },
                                            },
                                        }}
                                        muiTableBodyRowProps={({ row }) => ({
                                            //onClick: () => navigate(`/Usuario/${row.original.usuario_id}`),
                                            sx: {
                                                //  cursor: 'pointer',
                                            },
                                        })}
                                    />


                                    <div class="card-body">

                                        <button
                                            type="button"
                                            className="btn btn-warning mt-2"
                                            onClick={() => navigate('/ListaPublicacoes')}
                                        >
                                            Voltar
                                        </button>


                                        &nbsp;&nbsp;

                                        <button
                                            type="button"
                                            className="btn btn-danger mt-2"
                                            onClick={() => pdfPublicacao()}
                                        >
                                            PDF
                                        </button>




                                    </div>




                                </div>



                            </div>

                        </div>

                    </div>
                </div>

            </div>



        </div>

    )

}


export default Publicacao