import { useState } from 'react'
import api from "../components/api"
import { useNavigate, useParams } from 'react-router-dom'
import { validaCPF } from "../components/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';
import { propTypes } from 'react-bootstrap/esm/Image';


const ResgataSenha = (props) => {

    
    const [email, setEmail] = useState('')
    const [cpf, setCpf] = useState('')
    const [emailEnviado, setEmailEnviado] = useState(false)

    const EsquecimentoSenha = () => {


    
        if (!validaCPF(cpf) === '') {
            toast.error('CPF inválido!', { theme: 'colored' });
            return false;
        }

        if (email === '') {
            toast.error('e-mail não pode ficar em branco!', { theme: 'colored' });
            return false;
        }



        var dataPost = {

            email: email,
            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),

        }

        api.post(`esqueciMinhaSenha`, dataPost).then((result) => {
            var data = result.data.status;
            var descricao = result.data.descricao;

            if (data === 'ok') {
                //toast.success('E-mail com senha provisoria enviado! Verifique sua caixa de e-mail.', { theme: "colored" });

                setEmailEnviado(true)
                props.handleClose()

            } else {
                toast.error(data + ' - ' + descricao, { theme: 'colored' });
            }
        }).catch((err) => {
            console.log(err.response)
            toast.error('Usuário não existente na base de dados.', { theme: "colored" });
        });

    }

    return (

        <div>

            <div class="row">

                <div class="form-group mb-">
                    <label>CPF</label>
                    <div class="input-group">
                        <InputMask
                            mask="999.999.999-99"
                            type="text"
                            className="form-control form-control-lg"
                            value={cpf}
                            onChange={event => setCpf(event.target.value)}
                            id="formGroupExampleInput"
                        />
                        <span class="input-group-text">
                            <i class="bx bx-user text-4"></i>
                        </span>
                    </div>
                </div>

                <div class="form-group mb-3">
                    <div class="clearfix">
                        <label class="float-start">Email</label>
                    </div>
                    <div class="input-group">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            id="formGroupExampleInput2"
                            maxLength={255}
                        />
                        <span class="input-group-text">
                            <i class="bx bx-at text-4"></i>
                        </span>

                    </div>

                    <br />
                    

                    <div className="col-lg-12 d-flex justify-content-start mt-2">
                        <button type="button" className="btn btn-success" onClick={EsquecimentoSenha}>
                            Resgatar senha
                        </button>
                        
                    </div>

                    

                    <p style={{ marginTop: '20px' }}>
                        * Ao clicar em <strong>Resgatar senha</strong>, será enviado um e-mail com uma senha provisória para o endereço eletrônico informado.
                    </p>
                </div>
            </div>

        </div>

    )
    
}

export default ResgataSenha