import React, { useEffect, useState, useMemo } from 'react';
import api from "../components/api";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import UsuarioAnvisaDetalhe from '../components/UsuarioAnvisaDetalhe'

const NovosCadastros = () => {

  const [rowSelection, setRowSelection] = useState({})

  const navigate = useNavigate();

  const visaLogin = sessionStorage.getItem('visaLogin')
  const gvs_codigoLogin = sessionStorage.getItem('gvs_codigoLogin')
  const ibge_codigoLogin = sessionStorage.getItem('ibge_codigoLogin')
  const [slug, setSlug] = useState('')
  const [show, setShow] = useState(false)
  const [resultado, setResultado] = useState([]);

  
  // Definição das colunas na tabela
  const columns = useMemo(() => [
    {
      accessorKey: 'nome',
      header: 'Usuário',
    },

    {
      accessorKey: 'dataCadastro',
      header: 'Data e Hora de Cadastro',
    },

    {
      accessorKey: 'visa',
      header: 'VISA',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
          align: 'center',
      },
      Cell: ({ renderedCellValue, row }) => (
      
          <span className={`badge badge-${ renderedCellValue == 'CVS' ? 'primary' : renderedCellValue == 'GVS' ? 'info' : renderedCellValue == 'Município' ? 'warning' : '' }`}>{renderedCellValue}</span>
    
      ),
    },
    {
      accessorKey: 'gvs_descricao',
      header: 'GVS',
    },

    {
      accessorKey: 'IBGE_CIDADE',
      header: 'Município',
    },


    {
      accessorKey: 'usuarioSncr_Anvisa',
      header: 'Usuário do SNCR/Anvisa?',
    },


   

   
  ], []);


  const listaNovosCadastros = () => {

    var dataPost = {

      visa: visaLogin,
      gvs_codigo: gvs_codigoLogin,
      ibge_codigo: ibge_codigoLogin,

    }

    api.post(`listaNovosCadastros`, dataPost).then((result) => {
      const registros = result.data;
      //console.log(registros)
      setResultado(registros);

      //console.log(registros)

    })
      .catch((err) => {
        console.log(err.response);
      });
  };


  useEffect(() => {
   

      listaNovosCadastros();
    
    
  }, []);


  /*useEffect(() => {



    if (visaLogin === 'G') {
        setStatus_gvs('');
    }
    if (visaLogin === 'M') {
        setStatus('');
    }

    }, [visaLogin])*/





  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div data-collapsed="0" className="card">
            <div className="card-header">
              <div className="card-title">
                <h2 className="card-title">Novos Cadastros</h2>
                <br />
              </div>
            </div>

            <div className="card-body">



              <div className="row">
                <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact',  sorting: [{ id: 'nome', desc: false }], }}
                  enableRowSelection={false}
                  getRowId={(row) => row.historico_id} //give each row a more useful id
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    //onClick: () => navigate(`/UsuarioAnvisa/${row.original.slug}`),
                    onClick: () => {setShow(true);setSlug(row.original.slug)},
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

        <Modal
        show={show}
        onHide={() => setShow(false)}
        autoFocus={false}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-custom-with-margins"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Usuário Anvisa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          

          <UsuarioAnvisaDetalhe show={setShow} slug={slug} carregaUsuariosAnvisa={listaNovosCadastros} />
        
        </Modal.Body>
        
      </Modal>
      
      <ToastContainer />
    </div>
  );
};



export default NovosCadastros;
