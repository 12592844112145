import { useEffect, useState } from "react";
import api from "../components/api";
import { useNavigate, Link } from "react-router-dom";
import { validaCPF, validaEmail } from "../components/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Button from 'react-bootstrap/Button';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment'
import InputMask from 'react-input-mask';

const AutoCadastro = () => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isModalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();


    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [nomeMae, setNomeMae] = useState('');
    const [visa, setVisa] = useState('');
    const [ibge_codigo, setIbgeCodigo] = useState('');
    const [gvs_codigo, setGvsCodigo] = useState('');
    const [gestor, setGestor] = useState('');
    const [confirmaEmail, setConfirmaEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [emailEnviado, setEmailEnviado] = useState(false)
    const [emailFaleConosco, setEmailFaleConosco] = useState('')
    const [nomeFaleConosco, setNomeFaleConosco] = useState('')
    const [assunto, setAssunto] = useState('')
    const [ad_new, setAd_new] = useState('')
    const [usuarioSncr_Anvisa, setUsuarioSncr_Anvisa] = useState('')


    const [listaIbge, setListaIbge] = useState('');
    const [listaGvs, setListaGvs] = useState('');

    const [erroUsuario, setErroUsuario] = useState(0);

    const [politicaPrivacidade, setPoliticaPrivacidade] = useState(`I.	Da criação do Sistema Nacional de Controle de Receituários - SNCR, pela Anvisa, implementado pela Resolução da Diretoria Colegiada - RDC nº 873, de 27 de maio de 2024, que materializa a transformação digital do controle especial de substâncias, alinhando-se à Lei nº 13.732/2018, que reconhece a validade nacional dos receituários de controle especial.

II.	Que a VISA Municipal irá dispensar a numeração de Notificação de Receita B (NRB), B2 (NRB2) e retinóides de uso sistêmico (NRR) ao profissional e/ou instituição, para a confecção de talonários destas notificações, por meio do SNCR/Anvisa.

III.	Que o profissional e/ou instituição devem ser cadastrados no SNCR/Anvisa pelo usuário.

IV.	Que a dispensação da numeração ao profissional e/ou instituição, exige o cadastramento dos mesmos no SNCR/Anvisa e deve ser realizado com razoabilidade, ponderação e sensatez, considerando:
    •	A especialidade de atuação dos mesmos;
    •	No caso de instituições de saúde, considerar o porte da unidade, a quantidade de atendimentos e as especialidades atendidas;
    •	Seguir outras orientações e critérios de dispensação, estabelecidos e acordados pelo Sevisa. 

V.	Da criação, pelo CVS, do Sistema Estadual para Homologação Prévia do usuário do SNCR/Anvisa, ferramenta que estabelece controles para garantir a correta vinculação entre os usuários do SNCR/Anvisa, e suas respectivas unidades de Vigilância Sanitária Municipal.

VI.	Que a exigência de cadastramento no Sistema Estadual para Homologação Prévia do usuário do SNCR/Anvisa, e sua aprovação pelo gestor estadual é condição indispensável para que a Anvisa habilite o acesso dos usuários da Vigilância Sanitária Municipal ou GVS ao SNCR/Anvisa.

VII.	Que no ato do cadastramento no Sistema Estadual para Homologação Prévia do usuário do SNCR/Anvisa, deve ser realizada a autodeclaração para as funções de gestor municipal e gestor estadual ou de usuário.
Estou ciente da importância e da responsabilidade de informar de imediato ao gestor responsável, em caso de desligamento da unidade em que desempenho minhas funções, para a devida alteração no Sistema Estadual para Homologação Prévia do usuário no SNCR/Anvisa.

Quanto a política de privacidade: 
Confirmo consentimento expresso e livre para a coleta, armazenamento e utilização dos dados pessoais informados neste cadastro, de acordo com as condições previstas neste instrumento nos termos previstos no Artigo 9°, parágrafo terceiro, da Lei n° 13.709/2018 (Lei Geral de Proteção de Dados Pessoais).
`);


    const [checkBoxEstaClicada, setCheckBoxEstaClicada] = useState(false);

    const mudaStatusCheckBox = (event) => {
        setCheckBoxEstaClicada(event.target.checked);
    };


       

    const validaCPFUsuario = () => {
        
        var validaCPF = {

            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),

        }

        api.get(`usuarioAnvisaVerificaCPF/${cpf}`, validaCPF).then((result) => {

            if (result.data.status !== 'ok') {
                toast.error(result.data.descricao, { theme: 'colored' });
                setErroUsuario(1)
            } else {
                setErroUsuario(0)
            }
        }).catch((err) => {
            console.log(err.response);
        });
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const preventCopyPaste = (e) => {
        e.preventDefault();
        //Alerta('Copiar e colar não é permitido neste campo.');
    };


    const faleConosco = () => {


        if (assunto === '') {
            toast.error('Por favor, selecione um assunto.', { theme: 'colored' });
            return false;
        }


        if (mensagem === '') {
            toast.error('Mensagem não pode ficar em branco.', { theme: 'colored' });
            return false;
        }




        window.$.confirm({

            title: 'Confirmação',
            content: 'Confirma o envio de mensagem?',
            buttons: {
                yes: {
                    text: 'Sim',
                    btnClass: 'btn-green',
                    keys: ['enter', 'shift'],
                    action: function () {



                        var dataPost = {
                            emailFaleConosco: emailFaleConosco,
                            nomeFaleConosco: nomeFaleConosco,
                            assunto: assunto,
                            mensagem: mensagem,

                        }
                        //console.log(dataPost)
                        //return false

                        api.post(`faleConosco`, dataPost).then((result) => {
                            var data = result.data.status;
                            var descricao = result.data.descricao;

                            if (data === 'ok') {
                                setAssunto('')
                                setEmailFaleConosco('');
                                setNomeFaleConosco('');

                                setMensagem('');
                                setEmailEnviado(true);


                                /*console.log(dataPost)
                                return false*/

                                //toast.success('Mensagem Enviada.', { theme: "colored" });

                                handleClose();

                                //navigate('/AutoCadastro')

                                window.$.confirm({

                                    title: 'Mensagem enviada!',
                                    content: 'Aguarde que entraremos em contato.',
                                    buttons: {
                                        yes: {
                                            text: 'Ok',
                                            btnClass: 'btn-green',
                                            keys: ['enter', 'shift'],
                                            action: function () {


                                            }
                                        },

                                    }
                                });


                            } else {
                                toast.error(data + ' - ' + descricao, { theme: 'colored' });
                            }
                        })
                            .catch((err) => {
                                console.log(err.response);
                                toast.error('Erro ao enviar o e-mail.', { theme: "colored" });
                            });
                    }
                },
                no: {
                    text: 'Não',
                    btnClass: 'btn-red',
                    keys: ['esc'],
                    action: function () {
                        console.log('cancelado pelo usuário');
                    },
                },
            }
        });
    }



    async function salvaUsuario() {
        //console.log(dataNascimento)

        if (moment(dataNascimento, 'YYYY-MM-DD', true).isValid() == false) {
            toast.error('Data de nascimento inválida!', { theme: 'colored' });
            return false;
        }
        // Validações
        if (nome === '') {
            toast.error('Nome não pode ficar em branco!', { theme: 'colored' });
            return false;
        }

        if (gestor === '') {
            toast.error('Por favor, selecione se é gestor ou não!', { theme: 'colored' });
            return false;
        }

        if (usuarioSncr_Anvisa === '') {
            toast.error('Por favor, selecione se será usuario SNCR/Anvisa!', { theme: 'colored' });
            return false;
        }


        if (!validaCPF || '' || erroUsuario === 1) {
            toast.error('CPF inválido ou já existente na base de dados!', { theme: 'colored' });
            return false;
        }

        if (email != confirmaEmail || '') {
            toast.error('Por favor, confirme o e-mail.', { theme: 'colored' });
            return false;
        }


        if (nomeMae === '') {
            toast.error('Por favor, insira o nome da sua mãe.', { theme: 'colored' });
            return false;
        }

        if (visa === '') {
            toast.error('Por favor, insira o código VISA.', { theme: 'colored' });
            return false;
        } else {
            if (visa === 'G' || visa === 'M') {
                if (gvs_codigo === '') {
                    toast.error('Por favor, insira o GVS.', { theme: 'colored' });
                    return false;
                }
            }

            if (visa === 'M' && ibge_codigo === '') {
                toast.error('Por favor, insira o Município.', { theme: 'colored' });
                return false;
            }
        }


        


        window.$.confirm({
            title: 'Confirmação',
            content: 'Confirma enviar os dados?',
            buttons: {
                yes: {
                    text: 'Sim',
                    btnClass: 'btn-green',
                    keys: ['enter', 'shift'],
                    action: function () {

                        // Dados para envio
                        var dataPost = {
                            nome: nome,
                            gestor: gestor,
                            usuarioSncr_Anvisa: usuarioSncr_Anvisa,
                            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                            email: email,
                            dataNascimento: dataNascimento,
                            nomeMae: nomeMae,
                            visa: visa,
                            gvs_codigo: gvs_codigo,
                            ibge_codigo: ibge_codigo
                        }

                        api.post('usuarioAnvisa', dataPost).then((result) => {
                            var data = result.data.status;

                            if (data == 'ok') {
                                toast.success('Usuário salvo com sucesso', { theme: "colored" });

                                if (gestor == 1) {

                                    if (visa == 'C') { navigate('/AutoCadastroSucesso/C'); }
                                    if (visa == 'G') { navigate('/AutoCadastroSucesso/G'); }
                                    if (visa == 'M') { navigate('/AutoCadastroSucesso/M'); }

                                } else {
                                    navigate('/AutoCadastroSucesso/0');
                                }


                            } else {
                                toast.error(data, { theme: 'colored' });
                            }
                        }).catch((err) => {
                            toast.error('Erro ao salvar o usuário.' + err.message, { theme: "colored" });
                        });
                    }
                },
                no: {
                    text: 'Não',
                    btnClass: 'btn-red',
                    keys: ['esc'],
                    action: function () {
                        console.log('cancelado pelo usuário');
                    },
                },
            }
        });
    }



    async function carregaGvs() {
        try {
            const result = await api.get('listaGvs');

            setListaGvs(result.data.map(rs =>
                <option value={rs.gvs_codigo}>{rs.descricao}</option>
            ));
        } catch (err) {
            console.log(err.result);
        }
    }

    async function carregaMunicipio() {
        try {
            const result = await api.get(`listaMunicipio/${gvs_codigo}`);

            setListaIbge(result.data.map(rs =>
                <option value={rs.ibge_codigo}>{rs.ibge_cidade}</option>
            ));
        } catch (err) {
            console.log(err.result);
        }
    }


    /*const faleConoscoModal = async () => {
        handleClose();

    };*/



    useEffect(() => {

        if (visa === '' || visa === 'C') {
            setGvsCodigo('');
            setIbgeCodigo('');
        }

        if (visa === 'G') {
            setIbgeCodigo('');
        }

    }, [visa])


    useEffect(() => {

        carregaMunicipio()

    }, [gvs_codigo])


    useEffect(() => {

        carregaGvs()

    }, [])

    return (
        <div>
            <br />
            <br />
            <br />
            <div className="row justify-content-center" >
                <div className="col-lg-9" >

                    <div data-collapsed="0" className="card">

                        <div className="card-header">
                            <div className="card-title">
                                <h2 className="card-title">Sistema Estadual para Homologação Prévia do Usuário no SNCR/ANVISA</h2>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">CPF: *</label>
                                        <InputMask mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={event => setCpf(event.target.value)} onBlur={validaCPFUsuario} id="formGroupExampleInput" placeholder=""/>
                                    </div>
                                </div>

                                <div className="col-lg-9">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput2">Nome: *</label>
                                        <input type="text" className="form-control" value={nome} onChange={event => setNome(event.target.value)} id="formGroupExampleInput2" placeholder="" maxLength={255} />
                                    </div>
                                </div>


                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Data de Nascimento: *</label>
                                        <input type="date" className="form-control" value={dataNascimento} onChange={event => setDataNascimento(event.target.value)} id="formGroupExampleInput4" placeholder="" maxLength={255} />
                                    </div>
                                </div>

                                <div className="col-lg-9">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput7">Nome da mãe: *</label>
                                        <input type="text" className="form-control" value={nomeMae} onChange={event => setNomeMae(event.target.value)} id="formGroupExampleInput7" placeholder="" maxLength={255} />
                                    </div>
                                </div>


                               
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">É gestor? </label>
                                        &nbsp;<abbr title="Diretor ou Coordenador pelas ações de VISA no local.">[Ajuda]</abbr>
                                        <select className="form-control" value={gestor} onChange={event => setGestor(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Usuário do SNCR/Anvisa? </label>
                                        &nbsp;<abbr title="Será usuário do SISTEMA NACIONAL DE CONTROLE DE RECEITUÁRIOS da ANVISA?">[Ajuda]</abbr>
                                        <select className="form-control" value={usuarioSncr_Anvisa} onChange={event => setUsuarioSncr_Anvisa(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput3">E-mail: *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={email}
                                            onChange={handleChange}
                                            onCopy={preventCopyPaste}
                                            onPaste={preventCopyPaste}
                                            onCut={preventCopyPaste}
                                            id="formGroupExampleInput3"
                                            placeholder=""
                                            maxLength={255}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput7">
                                            Confirma E-mail: *
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={confirmaEmail}
                                            onChange={(event) => setConfirmaEmail(event.target.value)}
                                            id="formGroupExampleInput7"
                                            placeholder="Confirme seu e-mail"
                                            maxLength={255}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Visa</label>
                                        <abbr title="Áreas da Vigilância Sanitária."> [Ajuda] </abbr>
                                        <select className="form-control" value={visa} onChange={event => setVisa(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="C">CVS</option>
                                            <option value="G">GVS</option>
                                            <option value="M">Municipal</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4" >GVS</label>
                                        <abbr title="Grupos de Vigilância Sanitária, Responsável pela VISA da região."> [Ajuda]</abbr>
                                        <select
                                            className="form-control"
                                            value={gvs_codigo}
                                            onChange={event => setGvsCodigo(event.target.value)}
                                            disabled={visa !== 'G' && visa !== 'M'}
                                        >
                                            <option value="">[Selecione]</option>
                                            {listaGvs}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4" >Município </label>
                                        <abbr title="Reponsável pela VISA municipal."> [Ajuda] </abbr>
                                        <select
                                            className="form-control"
                                            value={ibge_codigo}
                                            onChange={event => setIbgeCodigo(event.target.value)}
                                            disabled={visa !== 'M'}
                                        >
                                            <option value="">[Selecione]</option>
                                            {listaIbge}
                                        </select>
                                    </div>
                                </div>

                                <p>&nbsp;</p>

                                <p>
                                    <strong>Termo de responsabilidade e política de privacidade</strong>
                                </p>

                                <p>
                                    <strong>Declaro estar ciente:</strong>
                                </p>

                                <textarea
                                    id="politicaPrivacidade"
                                    className="form-control"
                                    value={politicaPrivacidade}
                                    disabled={true}
                                    rows="10"
                                />

                                <p>&nbsp;</p>

                                <p>

                                    <div class="checkbox-custom checkbox-default">
                                        <input
                                            type="checkbox"
                                            id="respeitarOrientacoes"
                                            checked={checkBoxEstaClicada}
                                            onChange={mudaStatusCheckBox}


                                        />


                                        <label for="respeitarOrientacoes">Declaro que li atentamente e aceito respeitar as orientações, termos e condições acima especificados.</label>
                                    </div>
                                </p>

                                <div className="col-lg-5">

                                    <button
                                        type="button"
                                        className="btn btn-success mt-2"
                                        onClick={salvaUsuario}
                                        disabled={!checkBoxEstaClicada}
                                    >Salvar</button>

                                </div>

                                <p>
                                    <br />
                                    <Link style={{ textDecoration: 'bold', color: 'brown' }} onClick={handleShow}>
                                        Em caso de dúvidas, <strong>clique aqui</strong> e entre em contato conosco.
                                    </Link>

                                </p>



                            </div>
                        </div>
                    </div>
                </div>


                
                <ToastContainer />
            </div>

            <Modal show={show} onHide={handleClose} >
                    <Modal.Header>
                        <Modal.Title><b>Fale Conosco</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label">Assunto: *</label>
                                <select type="text" class="form-control" value={assunto} onChange={event => setAssunto(event.target.value)} placeholder="" maxLength={255} >
                                    <option value="">[Selecione]</option>
                                    <option value="Pré-Cadastro">Pré-Cadastro</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="formGroupExampleInput3">Nome: *</label>
                                <input type="text" className="form-control" value={nomeFaleConosco} onChange={event => setNomeFaleConosco(event.target.value)} id="formGroupExampleInput3" placeholder="" maxLength={255} />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="formGroupExampleInput3">E-mail: *</label>
                                <input type="text" className="form-control" value={emailFaleConosco} onChange={event => setEmailFaleConosco(event.target.value)} id="formGroupExampleInput3" placeholder="" maxLength={255} />
                            </div>
                        </div>


                        {/*<div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput3">E-mail: *</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="formGroupExampleInput3"
                                            value={emailFaleConosco}     
                                            onChange={(e) =>setEmailFaleConosco(e.target.value)}
                                            placeholder="Digite seu e-mail"
                                            maxLength={255}
                                        />
                                    </div>
                                </div>*/}
                        <br>
                        </br>
                        <div className="mt-3">
                            <b>Escreva sua mensagem:</b>
                            <textarea
                                className="form-control mt-2"
                                rows="5"
                                value={mensagem}
                                onChange={(e) => setMensagem(e.target.value)}
                                placeholder="Maxímo 5.000 caracteres"
                                maxLength={5000}
                            />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleClose}>
                            Fechar
                        </Button>

                        <Button className="btn btn-success"

                            onClick={faleConosco}>
                            Enviar
                        </Button>
                    </Modal.Footer>
                </Modal>

        </div>
    );


}

export default AutoCadastro;
