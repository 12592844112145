import { useEffect, useState, useMemo } from 'react';
import api from "../components/api";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const ListaPublicacoes = () => {

    const navigate = useNavigate();

    const columns = useMemo(() => [

        {
        accessorKey: 'numeroPublicacao',
        header: 'Número de Publicação',
        muiTableHeadCellProps: {
            align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ renderedCellValue, row }) => (
            
                <span className={`badge badge-info`}>{renderedCellValue}</span>
        
            ),

        },
    
        {
        accessorKey: 'ad_new',
        header: 'Data e hora',
        },

        {
        accessorKey: 'totalRegistrosAtivados',
        header: 'Ativações',
        muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
              align: 'right',
          },
        },

        {
        accessorKey: 'totalRegistrosInativados',
        header: 'Inativações',
        muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
              align: 'right',
          },
        },

    ], []);

  

    const [resultado, setResultado] = useState([]);
    const [totalPublicacoesAnvisa, setTotalPublicacoesAnvisa] = useState(0);
    const [numeroPublicacao, setNumeroPublicacao] = useState('');
    const [dataHoraEnvio, setDataHoraEnvio] = useState('');
    const [enviado, setEnviado] = useState('');
    const [publicacao_id, setPublicacao_id] = useState('');
    const [historico_id, setHistorico_id] = useState('');
    const [status, setStatus] = useState('');
    const [usuarioAnvisa_id, setUsuarioAnvisa_id] = useState('');

 


        const listaPublicados = () =>{

            api.get(`listaPublicados`).then((result) =>{
                const registros = result.data;
                setTotalPublicacoesAnvisa(registros.length);
                setResultado(registros);
            })
            .catch((err) => {
                console.log(err.response);
            });
        };

        useEffect(() =>{

            listaPublicados()


        }, [])



    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div data-collapsed="0" className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <h2 className="card-title">Listagem de Ofícios ANVISA</h2>
                                <br />
                                <span className="badge badge-success">{totalPublicacoesAnvisa}</span>
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <button
                                type="button"
                                className="btn btn-warning mt-2"
                                onClick={() => navigate('/Historico')}
                            >
                                Pendências de Ofícios ANVISA
                            </button>
                            &nbsp;
                            <br /><br />
                            <div className="row">
                                <MaterialReactTable
                                    columns={columns}
                                    data={resultado}
                                    localization={MRT_Localization_PT_BR}
                                    initialState={{ density: 'compact' }}
                                    muiTableHeadCellProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '8px',
                                                sm: '9px',
                                                md: '10px',
                                                lg: '11px',
                                                xl: '12px',
                                            },
                                        },
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '8px',
                                                sm: '9px',
                                                md: '10px',
                                                lg: '11px',
                                                xl: '12px',
                                            },
                                        },
                                    }}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () => navigate(`/publicacao/${row.original.publicacao_id}`),
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ListaPublicacoes;

