import { useState } from 'react'
import { Outlet, Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import api from './api';

import Menu from "./Menu"

const Layout = () => {


 const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarCollapsed(!isSidebarCollapsed);
      document.documentElement.classList.toggle('sidebar-left-collapsed');
    };



    const [show, setShow] = useState(false);
    const [senha, setSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //
    const nomeLogin = sessionStorage.getItem('nomeLogin')
    const emailLogin = sessionStorage.getItem('emailLogin')
    const visaLogin = sessionStorage.getItem('visaLogin')
    const usuario_id = sessionStorage.getItem('usuario_id')


    const logout = () => {

        sessionStorage.clear()
        window.location.href = '/'
        
    }

    const salvaSenhaModal = async () => {
        if (senha !== confirmaSenha) {
            toast.error('As senhas não coincidem!', { theme: 'colored' });
            console.log('Erro: as senhas não coincidem');
            return false;
        }

        const dataPost = {
            senha: senha,
            confirmaSenha: confirmaSenha,
        };

        try {
            const result = await api.post(`usuarioAlteraSenha/${usuario_id}`, dataPost);
            console.log(result.data);
            handleClose();
            toast.success('Senha alterada com sucesso!', { theme: 'colored' });
        } catch (err) {
            console.log(err.response);
            toast.error('Erro ao alterar a senha. Tente novamente.', { theme: 'colored' });
        }
    };




    const faleConoscoModal = async () => {
        if (senha !== confirmaSenha) {
            toast.error('As senhas não coincidem!', { theme: 'colored' });
            console.log('Erro: as senhas não coincidem');
            return false;
        }

        const dataPost = {
            senha: senha,
            confirmaSenha: confirmaSenha,
        };

        try {
            const result = await api.post(`usuarioAlteraSenha/${usuario_id}`, dataPost);
            console.log(result.data);
            handleClose();
            toast.success('Senha alterada com sucesso!', { theme: 'colored' });
        } catch (err) {
            console.log(err.response);
            toast.error('Erro ao alterar a senha. Tente novamente.', { theme: 'colored' });
        }
    };








    return (

        <div>


            <section class="body">

            
            <header class="header">
                <div class="logo-container">
                    <a href="/Home" class="logo">
                        <img src="/img/logo.png" width="75" height="35" alt="Porto Admin" />
                    </a>

                    <div class="d-md-none toggle-sidebar-left" data-toggle-class="sidebar-left-opened" data-target="html" data-fire-event="sidebar-left-opened">
                        <i class="fas fa-bars" aria-label="Toggle sidebar"></i>
                    </div>

                </div>

                
                <div class="header-right">

                    <form  class="search nav-form" style={{ display: 'none'}}>
                        <div class="input-group">
                            <input type="text" class="form-control" name="q" id="q" placeholder="Buscar..." />
                            <button class="btn btn-default" type="button"><i class="bx bx-search"></i></button>
                        </div>
                    </form>

                    <span class="separator"></span>

                    <div id="userbox" class="userbox">
                        <a href="#" data-bs-toggle="dropdown">
                            <figure class="profile-picture" style={{ display: 'none'}}>
                                <img src="/img/%21logged-user.jpg" alt="Joseph Doe" class="rounded-circle" data-lock-picture="img/%21logged-user.jpg" />
                            </figure>
                            <div class="profile-info" data-lock-name={nomeLogin} data-lock-email={emailLogin}>
                                <span class="name">{nomeLogin}</span>
                                {/* <span className="role">
                           {visaLogin === 'M' 
                                ? 'Municípal' 
                                : visaLogin === 'G' 
                                ? 'GVS' 
                                : visaLogin === 'C' 
                                ? 'CVS' 
                                : ''}
                            </span>*/}
                                <span class="role">{emailLogin}</span>
                               
                            </div>

                            <i class="fa custom-caret"></i>
                        </a>

                        <div class="dropdown-menu">
                            <ul class="list-unstyled mb-2">
                                <li class="divider"></li>
                                <li style={{ display: 'none'}}>
                                    <a role="menuitem" tabindex="-1" href="#"><i class="bx bx-user-circle"></i> Meu perfil</a>
                                </li>
                                <li>
                                    <a role="menuitem" tabindex="-1" onClick={handleShow} href="#" data-lock-screen="true"><i class="bx bx-lock"></i> Alterar senha</a>
                                </li>
                                <li>
                                    <a role="menuitem" tabindex="-1" onClick={() => logout()}><i class="bx bx-power-off"></i> Sair</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </header>
            

            <div class="inner-wrapper">

<aside id="sidebar-left" class="sidebar-left">

    <div class="sidebar-header">
        <div class="sidebar-title">
            &nbsp;
        </div>
        <div className="sidebar-toggle d-none d-md-block" onClick={toggleSidebar}>
            <i className="fas fa-bars" aria-label="Toggle sidebar"></i>
        </div>
    </div>

    <div class="nano">
        <div class="nano-content">
            <nav id="menu" class="nav-main" role="navigation">

                <ul class="nav nav-main">

                    <Menu />

                </ul>
            </nav>

            <hr class="separator" />



        </div>

                    </div>

                </aside>
             
                <section role="main" class="content-body">
                    <header class="page-header">
                        <h2>&nbsp;</h2>

                        <div class="right-wrapper text-end">
                            <ol class="breadcrumbs">
                        
                            </ol>

                            &nbsp;
                            &nbsp;


                        </div>
                    </header>

                    
                    

                    <Outlet />
                    
                    
               
                </section>
            </div>

            

            </section>

            
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><b>Alterar Senha</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="form-group">
                            <label class="col-form-label"><b>Senha: *</b></label>
                            <input 
                                type="password" 
                                class="form-control" 
                                value={senha} 
                                onChange={event => setSenha(event.target.value)} 
                                maxLength={25} 
                            />
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><b>Confirma senha: *</b></label>
                            <input 
                                type="password" 
                                class="form-control" 
                                value={confirmaSenha} 
                                onChange={event => setConfirmaSenha(event.target.value)} 
                                maxLength={25} 
                            />
                        </div>

                        <div class="col-lg-12" >
                            <div class="form-group">
                                <br />
                            </div>
                        </div>

                        <div class="col-lg-12" >
                            <div class="col-form-label">
                                <div class="form-group">
                                    <p><b>A senha deve conter um mínimo de 6 caracteres.</b></p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Fechar</Button>
                        <Button variant="success" onClick={salvaSenhaModal}>Salvar</Button>
                    </Modal.Footer>
                </Modal>

                <ToastContainer />
        </div>

    )

}

export default Layout