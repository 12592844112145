import React, { useEffect, useState, useMemo } from "react";
import api from "../components/api";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import Modal from 'react-bootstrap/Modal'
import GestorMunicipalDetalhe from '../components/GestorMunicipalDetalhe'
import 'bootstrap/dist/css/bootstrap.min.css';

const GestoresRegionais = () => {
    const navigate = useNavigate();


    const [resultado, setResultado] = useState([]);
    const [totalGestoresMunicipais, setTotalGestoresMunicipais] = useState(0);
    const [show, setShow] = useState(false);
    const [slug, setSlug] = useState('')

    const visaLogin = sessionStorage.getItem('visaLogin')
    const ibge_codigoLogin = sessionStorage.getItem('ibge_codigoLogin')
    const gvs_codigoLogin = sessionStorage.getItem('gvs_codigoLogin')


    // Definição das colunas na tabela
    const columns = useMemo(() => [
        {
            accessorKey: 'nome',
            header: 'Nome',
        },
        {
            accessorKey: 'cpf',
            header: 'CPF',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            muiTableHeadCellProps: {
                align: 'center',
              },
              muiTableBodyCellProps: {
                  align: 'center',
              },
              Cell: ({ renderedCellValue, row }) => (
              
                  <span className={`badge badge-${ renderedCellValue == 'Ativo' ? 'success' : renderedCellValue == 'Inativo' ? 'danger' : 'primary' }`}>{renderedCellValue}</span>
            
              ),
        },
        {
            accessorKey: 'usuarioSncr_Anvisa',
            header: 'Usuário do SNCR/Anvisa?',
          },

        {
            accessorKey: 'visa',
            header: 'Visa',
        },
        {
            accessorKey: 'gvs_descricao',
            header: 'GVS',
        },
        {
            accessorKey: 'ibge_cidade',
            header: 'Município',
        },

    ], []);

    const carregaGestoresMunicipais = () => {

        var dataPost = {

            visa: visaLogin,
            gvs_codigo: gvs_codigoLogin,
            ibge_codigo: ibge_codigoLogin,
            tipoGestor: 'M',
        }



        api.post(`gestores`, dataPost)
            .then((result) => {
                const registros = result.data;
                setTotalGestoresMunicipais(registros.length);
                setResultado(registros);
            })
            .catch((err) => {
                console.log(err.response);
            });
    };



    useEffect(() => {

        carregaGestoresMunicipais();

    }, [visaLogin]);





    return (

        <div>

            <div class="row">
                <div class="col-lg-12">

                    <div data-collapsed="0" class="card">

                        <div class="card-header">
                            <div class="card-title">

                                <h2 class="card-title"> Listagem dos Gestores Municipais</h2>
                                <br />
                                <span className="badge badge-success">{totalGestoresMunicipais}</span>
                            </div>
                        </div>

                        <div class="card-body">

                            <div class="row">

                                <MaterialReactTable
                                    columns={columns}
                                    data={resultado}
                                    localization={MRT_Localization_PT_BR}
                                    initialState={{ density: 'compact' }}
                                    muiTableHeadCellProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '8px',
                                                sm: '9px',
                                                md: '10px',
                                                lg: '11px',
                                                xl: '12px',
                                            },
                                        },
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '8px',
                                                sm: '9px',
                                                md: '10px',
                                                lg: '11px',
                                                xl: '12px',
                                            },
                                        },
                                    }}
                                    muiTableBodyRowProps={({ row }) => ({
                                        //onClick: () => navigate(`/GestorMunicipal/${row.original.slug}`),
                                        onClick: () => {setSlug(row.original.slug);setShow(true)},
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                />

                            </div>

                        </div>

                    </div>
                </div>


            </div>


            <Modal
                show={show}
                onHide={() => setShow(false)}
                autoFocus={false}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="modal-custom-with-margins"
                fullscreen
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    Gestor Municipal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    

                    <GestorMunicipalDetalhe show={setShow} slug={slug} carregaGestoresMunicipais={carregaGestoresMunicipais} />
                
                </Modal.Body>
                
                </Modal>

                



            <ToastContainer />

        </div>

    );
};

export default GestoresRegionais;
