import React, { useEffect, useState, useMemo } from "react";
import api from "../components/api";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import Modal from 'react-bootstrap/Modal'

import UsuarioAnvisaDetalhe from '../components/UsuarioAnvisaDetalhe'
import 'bootstrap/dist/css/bootstrap.min.css';

const UsuariosAnvisa = () => {
  const navigate = useNavigate();




  const [resultado, setResultado] = useState([]);
  const [totalUsuariosAnvisa, setTotalUsuariosAnvisa] = useState(0);
  const [slug, setSlug] = useState('')
  const [status_gvs, setStatus_gvs] = useState('')
  const [status, setStatus] = useState('')
  const [show, setShow] = useState(false);



  const visaLogin = sessionStorage.getItem('visaLogin')
  const ibge_codigoLogin = sessionStorage.getItem('ibge_codigoLogin')
  const gvs_codigoLogin = sessionStorage.getItem('gvs_codigoLogin')
  


  // Definição das colunas na tabela
  const columns = useMemo(() => [
    {
      accessorKey: 'visa',
      header: 'Visa',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
          align: 'center',
      },
      Cell: ({ renderedCellValue, row }) => (
      
          <span className={`badge badge-${ renderedCellValue == 'CVS' ? 'primary' : renderedCellValue == 'GVS' ? 'info' : renderedCellValue == 'Município' ? 'warning' : '' }`}>{renderedCellValue}</span>
    
      ),
      
    },
    {
      accessorKey: 'nome',
      header: 'Nome',
    },

    {
      accessorKey: 'status',
      header: 'Aprovação 1º nível',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
          align: 'center',
      },
      Cell: ({ renderedCellValue, row }) => (
      
          <span className={`badge badge-${ renderedCellValue == 'Ativo' ? 'success' : renderedCellValue == 'Inativo' ? 'danger' : 'primary' }`}>{renderedCellValue}</span>
    
      ),
    },

    {
      accessorKey: 'status_gvs',
      header: 'Aprovação 2º nível',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
          align: 'center',
      },
      Cell: ({ renderedCellValue, row }) => (
      
          <span className={`badge badge-${ renderedCellValue == 'Ativo' ? 'success' : renderedCellValue == 'Inativo' ? 'danger' : 'primary' }`}>{renderedCellValue}</span>
    
      ),
    },

    {
      accessorKey: 'ad_new',
      header: 'Data de Cadastro',
    },

    {
      accessorKey: 'gestor',
      header: 'É gestor?',
    },

    {
      accessorKey: 'usuarioSncr_Anvisa',
      header: 'Usuário do SNCR/Anvisa?',
    },

    {
      accessorKey: 'cpf',
      header: 'CPF',
    },
    {
      accessorKey: 'dataNascimento',
      header: 'Data de Nascimento',
    },
    {
      accessorKey: 'nomeMae',
      header: 'Nome da Mãe',
    },
    
    {
      accessorKey: 'gvs_descricao',
      header: 'GVS',
    },
    {
      accessorKey: 'municipioDescricao',
      header: 'Município',
    },

  ], []);

  const carregaUsuariosAnvisa = () => {


    var dataPost = {

      visa: visaLogin,
      gvs_codigo: gvs_codigoLogin,
      ibge_codigo: ibge_codigoLogin,

 
    }


    api.post('usuariosAnvisa', dataPost)
      .then((result) => {
        const registros = result.data;
        setTotalUsuariosAnvisa(registros.length);
        setResultado(registros);


        return false
      })
      .catch((err) => {
        console.log(err.response);
      });
  };






  useEffect(() => {

    carregaUsuariosAnvisa();

  }, [visaLogin]);



  return (

    <div>

      <div class="row">
        <div class="col-lg-12">

          <div data-collapsed="0" class="card">

            <div class="card-header">
              <div class="card-title">

                <h2 class="card-title"> Listagem de Usuários ANVISA</h2>
                <br />
                <span className="badge badge-success">{totalUsuariosAnvisa}</span>
              </div>
            </div>

            <div class="card-body">
              {/* <button
                type="button"
                className="btn btn-warning mt-2"
                onClick={() => navigate('/UsuarioAnvisa')}
              >
                + Adicionar novo usuário ANVISA
              </button> */}


              <div class="row">


                <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact' }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    //onClick: () => navigate(`/UsuarioAnvisa/${row.original.slug}`),
                    onClick: () => {setSlug(row.original.slug);setShow(true)},
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                />




              </div>

            </div>

          </div>
        </div>


      </div>

      <ToastContainer />


      <Modal
      show={show}
      onHide={() => setShow(false)}
      autoFocus={false}
      aria-labelledby="example-custom-modal-styling-title"
      dialogClassName="modal-custom-with-margins"
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Usuário Anvisa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        

        <UsuarioAnvisaDetalhe show={setShow} slug={slug} carregaUsuariosAnvisa={carregaUsuariosAnvisa} />
      
      </Modal.Body>
      
    </Modal>

    </div>



  );
};

export default UsuariosAnvisa;
