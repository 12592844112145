import { useState } from 'react'
import api from "../components/api"
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResgataSenha from '../components/ResgataSenha'

const Login = () => {

    const navigate = useNavigate()

    const [cpf, setCpf] = useState('')
    const [senha, setSenha] = useState('')

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const efetuaLogin = (event) => {
        // Evitar o comportamento padrão ao pressionar "Enter"
        if (event) event.preventDefault();

        var dataPost = {
            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
            senha: senha,
        }

        api.post('login', dataPost).then((result) => {
            var data = result.data[0]

            if (data) {
                sessionStorage.setItem('nomeLogin', data.nome)
                sessionStorage.setItem('usuario_id', data.usuario_id)
                sessionStorage.setItem('emailLogin', data.email)
                sessionStorage.setItem('statusLogin', data.status)
                sessionStorage.setItem('perfilLogin', data.perfil)
                sessionStorage.setItem('visaLogin', data.visa)
                sessionStorage.setItem('ibge_codigoLogin', data.ibge_codigo)
                sessionStorage.setItem('gvs_codigoLogin', data.gvs_codigo)

                navigate('/Home')
            } else {
                toast.error('Usuário ou senha inválida', { theme: "colored" });
            }
        }).catch((err) => {
            console.log(err.response)
        })
    }

    return (
        <div>
            <section className="body-sign">
                <div className="center-sign">
                    <a href="#" className="logo float-start">
                        <img src="img/logo.png" height="70" alt="Porto Admin" />
                    </a>

                    <div className="panel card-sign">
                        <div className="card-title-sign mt-3 text-end">
                            <h2 className="title text-uppercase font-weight-bold m-0">
                                <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i> Login
                            </h2>
                        </div>
                        <div className="card-body">
                            {/* Remova a submissão automática de formulário */}
                            <form onSubmit={(event) => efetuaLogin(event)}>
                                <div className="form-group mb-">
                                    <label>CPF</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control form-control-lg" value={cpf} onChange={event => setCpf(event.target.value)} />
                                        <span className="input-group-text">
                                            <i className="bx bx-user text-4"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <div className="clearfix">
                                        <label className="float-start">Senha</label>
                                    </div>
                                    <div className="input-group">
                                        <input 
                                            type="password" 
                                            className="form-control form-control-lg" 
                                            value={senha} 
                                            onChange={event => setSenha(event.target.value)}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    efetuaLogin(event); // Executa o login ao pressionar "Enter"
                                                }
                                            }} 
                                        />
                                        <span className="input-group-text">
                                            <i className="bx bx-lock text-4"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="">
                                            <div className="form-group mb-3">
                                                <div className="input-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light mt-2"
                                                        //onClick={() => navigate('/EsqueciMinhaSenha')}
                                                        onClick={handleShow}
                                                    >
                                                        Esqueci minha senha
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4 text-end">
                                        <button 
                                            type="submit" 
                                            className="btn btn-info mt-2">
                                            Entrar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


            <Modal show={show} onHide={handleClose} >
                    <Modal.Header>
                        <Modal.Title><b>Resgata senha</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                       <ResgataSenha handleClose={handleClose}/>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleClose}>
                            Fechar
                        </Button>

                    </Modal.Footer>
                </Modal>

            <ToastContainer />
        </div>
    );
}

export default Login;
