import { useEffect, useState } from "react";
import api from "../components/api";
import { useNavigate, useParams } from "react-router-dom";
import { validaCPF } from "../components/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';


const GestorRegional = () => {

    const navigate = useNavigate();

    const { slug } = useParams()

    const ad_usr = sessionStorage.getItem('usuario_id')
    const statusLogin = sessionStorage.getItem('statusLogin')
    const visaLogin = sessionStorage.getItem('visaLogin')

    const [resultado, setResultado] = useState([]);
    const [totalGestoresRegionais, setTotalGestoresRegionais] = useState(0);
    const [usuarioSncr_Anvisa, setUsuarioSncr_Anvisa] = useState('')

   

    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [visa, setVisa] = useState('');
    const [gvs_codigo, setGvsCodigo] = useState('');
    const [ibge_codigo, setIbgeCodigo] = useState('');
    const [gestor, setGestor] = useState('');
    const [nomeMae, setNomeMae] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [ad_new, setAd_new] = useState();
    const [ad_upd, setAd_upd] = useState();
    const [usuarioAnvisa_id, setUsuarioAnvisa_id] = useState();



    const [listaIbge, setListaIbge] = useState('');
    const [listaGvs, setListaGvs] = useState('');




    const carregaUsuario = () => {

        //console.log(`usuarioAnvisa/${slug}`)

        api.get(`usuarioAnvisa/${slug}`).then((result) => {


            console.log(`usuarioAnvisa/${slug}` + 'aqui')


            setNome(result.data[0].nome)
            setCpf(result.data[0].cpf)
            setGestor(result.data[0].gestor ? "1" : "0");
            setUsuarioSncr_Anvisa(result.data[0].usuarioSncr_Anvisa ? "1" : "0");
            setEmail(result.data[0].email)
            setDataNascimento(result.data[0].dataNascimento)
            setNomeMae(result.data[0].nomeMae)
            setStatus(result.data[0].status == 'Inativo' ? 'I' : result.data[0].status == 'Ativo' ? 'A' : null)
            setVisa(result.data[0].visa)
            setIbgeCodigo(result.data[0].ibge_codigo)
            setGvsCodigo(result.data[0].gvs_codigo)
            setAd_new(result.data[0].ad_new)
            setAd_upd(result.data[0].ad_upd)
            setUsuarioAnvisa_id(result.data[0].usuarioAnvisa_id)

        }).catch((err) => {
            console.log(err.response);
        });
    };


    async function apagaUsuarioAnvisa() {

        window.$.confirm({
            title: 'Confirmação',
            content: 'Confirma exclusão?',
            buttons: {
                yes: {
                    text: 'Sim',
                    btnClass: 'btn-green',
                    keys: ['enter', 'shift'],
                    action: function () {

                        // inserir ação para sim

                        //console.log(dataPost)
                        //return false

                        if (usuarioAnvisa_id) {

                            api.delete(`usuarioAnvisa/${usuarioAnvisa_id}`).then((result) => {

                                console.log(result.data)

                                toast.success('Registro excluído com sucesso!', { theme: "colored" });

                                navigate('/UsuariosAnvisa');

                            }).catch((err) => {
                                console.log()
                            })

                        }

                    }

                },
                cancel: {
                    text: 'Não',
                    btnClass: 'btn-red',
                    keys: ['enter', 'shift'],
                    action: function () {

                        // inserir ação para nao

                        console.log('cancelado pelo usuário')

                    },
                },

            }
        });

    }



    const carregaGvs = async () => {

        try {

            const result = await api.get(`listaGvs`);
            setListaGvs(result.data.map(rs =>

                <option value={rs.gvs_codigo}>{rs.descricao}</option>

            ));

        } catch (err) {
            console.log(err.result);
        }

    };


    async function carregaMunicipio() {
        try {
            const result = await api.get(`listaMunicipio/${gvs_codigo}`);

            setListaIbge(result.data.map(rs =>
                <option value={rs.ibge_codigo}>{rs.ibge_cidade}</option>
            ));
        } catch (err) {
            console.log(err.result);
        }
    }


    const salvaStatus = () => {


    window.$.confirm({
        title: 'Confirmação',
        content: 'Confirma salvar?',
        buttons: {
            yes: {
                text: 'Sim',
                btnClass: 'btn-green',
                keys: ['enter', 'shift'],
                action: function () {

                    // inserir ação para sim

                    if (slug) { // ok

                        var dataPost = {

                                                
                            nome: nome,
                            gestor: gestor,
                            cpf: cpf,
                            email: email,
                            dataNascimento: dataNascimento,
                            nomeMae: nomeMae,
                            visa: visa,
                            gvs_codigo: gvs_codigo,
                            ibge_codigo: ibge_codigo,
                            status: status,
                            ad_usr: ad_usr,

                        }

                        //console.log(dataPost)
                        //return false

                        api.put(`usuarioAnvisa/${usuarioAnvisa_id}`, dataPost).then((result) => {

                            //console.log(result.data)
                            //navigate('/GestoresRegionais')
                            
                            if (result.data.status == 'ok') {

                                toast.success('Registro salvo com sucesso!', { theme: "colored" });

                             }

                        }).catch((err) => {

                            console.log(err.response)

                        })

                        /*

                        var dataPost = {
                            status: status
                        }

            
                        api.put(`atualizaStatus/${slug}`, dataPost).then((result) => {

                            //console.log(result.data)
                            var data = result.data.status;
                             //var usuario_id = result.data.usuario_id;
            
                             if (data == 'ok') {

                                toast.success('Registro salvo com sucesso!', { theme: "colored" });

                             }
                           
                        }).catch((err) => {
                            console.log(err.response)
                            toast.error('Erro ao salvar o usuário.' + err.message, { theme: "colored" });
                        });

                        */
                    }

                }
            },
            cancel: {
                text: 'Não',
                btnClass: 'btn-red',
                keys: ['enter', 'shift'],
                action: function () {

                    // inserir ação para nao
                    //console.log('cancelado pelo usuário')

                },
            },

        }
    });
    }




    useEffect(() => {

        carregaGvs()
        carregaUsuario()


    }, [slug])

    useEffect(() => {

        carregaMunicipio()

    }, [gvs_codigo])








    return (

        <div>
            <div className="row justify-content-center" >
                <div className="col-lg-12" >
                    <div data-collapsed="0" className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <h2 className="card-title">Gestor Regional</h2>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">CPF: *</label>
                                        <InputMask mask='999.999.999-99' type="text" class="form-control" value={cpf} onChange={event => setCpf(event.target.value)} placeholder="" disabled />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput2">Nome: *</label>
                                        <input type="text" className="form-control" value={nome} onChange={event => setNome(event.target.value)} id="formGroupExampleInput2" placeholder="" maxLength={255} disabled />
                                    </div>
                                </div>



                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Data de Nascimento: *</label>
                                        <input type="date" className="form-control" value={dataNascimento} onChange={event => setDataNascimento(event.target.value)} id="formGroupExampleInput4" placeholder="" maxLength={255} />
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput7">Nome da mãe: *</label>
                                        <input type="text" className="form-control" value={nomeMae} onChange={event => setNomeMae(event.target.value)} id="formGroupExampleInput7" placeholder="" maxLength={255} />
                                    </div>
                                </div>



                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="gestor">É gestor? *</label>
                                        <select className="form-control" value={gestor} onChange={event => setGestor(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="gestor">Usuário do SNCR/Anvisa? *</label>
                                        <select className="form-control" value={usuarioSncr_Anvisa} disabled={visaLogin !== 'C' ? true : false} onChange={event => setUsuarioSncr_Anvisa(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput3">E-mail: *</label>
                                        <input type="text" className="form-control" value={email} onChange={event => setEmail(event.target.value)} id="formGroupExampleInput3" placeholder="" maxLength={255} />
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Status: *</label>
                                        <select className="form-control" value={status} onChange={event => setStatus(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="A">Ativo</option>
                                            <option value="I">Inativo</option>
                                        </select>
                                    </div>
                                </div>



                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Visa: *</label>
                                        <select className="form-control" value={visa} onChange={event => setVisa(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="C">CVS</option>
                                            <option value="G">GVS</option>
                                            <option value="M">Municipal</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4" >GVS: </label>
                                        <select
                                            className="form-control"
                                            value={gvs_codigo}
                                            onChange={event => setGvsCodigo(event.target.value)}
                                            disabled={visa === '' || visa === 'C'}
                                        >
                                            <option value="">[Selecione]</option>
                                            {listaGvs}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4" >Município: </label>
                                        <select
                                            className="form-control"
                                            value={ibge_codigo}
                                            onChange={event => setIbgeCodigo(event.target.value)}
                                            disabled={gvs_codigo === '' || visa === '' || visa === 'G'}
                                        >
                                            <option value="">[Selecione]</option>
                                            {listaIbge}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput7">Data de cadastro</label>
                                        <input type="text" className="form-control" value={ad_new} disabled />
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput7">Última atualização</label>
                                        <input type="text" className="form-control" value={ad_upd} disabled />
                                    </div>
                                </div>

                                <div className="col-lg-5">

                                    <br />
                                    <button type="button" className="btn btn-warning mt-2" onClick={() => navigate('/GestoresRegionais')} >Voltar</button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn-success mt-2" onClick={salvaStatus}>Salvar</button> &nbsp;
                                    <button type="button" className="btn btn-danger mt-2" onClick={apagaUsuarioAnvisa} style={{ display: /*sessionStorage.getItem('visaLogin') === 'M' ||*/ sessionStorage.getItem('visaLogin') === 'C' ? '': 'none' || (statusLogin) === '0' }}>Apagar</button> &nbsp;
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>

        </div>
    );
};


export default GestorRegional;
