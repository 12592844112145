import { useEffect, useState } from "react";
import api from "../components/api";
import { useNavigate, useParams } from "react-router-dom";
import { validaCPF } from "../components/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const MensagemFaleConosco = () => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowModal = () => setShow()

  const [isModalOpen, setModalOpen] = useState(false);


  const navigate = useNavigate();

  const { faleConosco_id } = useParams()


  const visaLogin = sessionStorage.getItem('visaLogin')
  const statusLogin = sessionStorage.getItem('statusLogin')
  const nomeLogin = sessionStorage.getItem('nomeLogin')
  const emailLogin = sessionStorage.getItem('emailLogin')
  const usuario_idLogin = sessionStorage.getItem('usuario_id')



  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [emailFaleConosco, setEmailFaleConosco] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [mensagemResposta, setMensagemResposta] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [visa, setVisa] = useState('');
  const [ibge_codigo, setIbgeCodigo] = useState('');
  const [gvs_codigo, setGvsCodigo] = useState('');
  const [status, setStatus] = useState('');
  const [ad_new, setAd_new] = useState('');
  const [ad_upd, setAd_upd] = useState('');
  const [gestor, setGestor] = useState('');
  const [assunto, setAssunto] = useState('');
  const [nomeFaleConosco, setNomeFaleConosco] = useState('');
  const [uuid, setUuid] = useState('');
  const [usuario_id, setUsuario_id] = useState();
  const [gvs_descricao, setGvsDescricao] = useState('');
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [assunto2, setAssunto2] = useState('')


  const [listaIbge, setListaIbge] = useState('');
  const [listaGvs, setListaGvs] = useState('');

  const [erroUsuario, setErroUsuario] = useState(0);
  const [disabled, setDisabled] = useState(false)


  /*
  const validaCPFUsuario = () => {

      var dataPost = {

          cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
          slug: slug,

      }

      api.get(`usuarioAnvisaVerificaCPF/${cpf}`, dataPost).then((result) => {

          if (result.data.status !== 'ok') {
              toast.error(result.data.descricao, { theme: 'colored' });
              setErroUsuario(1)
          } else {
              setErroUsuario(0)
          }
      }).catch((err) => {
          console.log(err.response);
      });
  };
  */
  const faleConoscoResposta = () => {


    if (assunto2 === '') {
      toast.error('Assunto não pode ficar em branco.', { theme: 'colored' });
      return false;
    }


    if (mensagemResposta === '') {
      toast.error('Resposta não pode ficar em branco.', { theme: 'colored' });
      return false;
    }




    window.$.confirm({

      title: 'Confirmação',
      content: 'Confirma o envio da resposta?',
      buttons: {
        yes: {
          text: 'Sim',
          btnClass: 'btn-green',
          keys: ['enter', 'shift'],
          action: function () {

            var dataPost = {

              emailFaleConosco: emailFaleConosco,
              faleConosco_id: faleConosco_id,
              nomeResposta: nomeLogin,
              usuario_idResposta: usuario_idLogin,
              mensagemResposta: mensagemResposta,
              assunto: assunto
            }


            api.post(`faleConoscoResposta`, dataPost).then((result) => {
              var data = result.data.status;
              var descricao = result.data.descricao;


              if (data === 'ok') {
                /* setEmailFaleConosco('');
                setNomeFaleConosco('');
                setMensagem(''); */
                setEmailEnviado(true);

                console.log('chegamos aqui!')

                handleClose();

                toast.success('Resposta enviada com sucesso!', { theme: 'colored' });

                console.log(data)
                console.log(descricao)

                //navigate('/MensagensFaleConosco')

              } else {

                toast.error(data + ' - ' + descricao, { theme: 'colored' });
              }
            })
              .catch((err) => {
                console.log(err.response);
                toast.error('Erro ao enviar o e-mail.', { theme: "colored" });
              });
          }
        },
        no: {
          text: 'Não',
          btnClass: 'btn-red',
          keys: ['esc'],
          action: function () {
            console.log('cancelado pelo usuário');
          },
        },
      }
    });
  }



  const carregaMensagem = () => {



    api.get(`faleConosco/${faleConosco_id}`).then((result) => {

      //console.log(result.data)

      setNomeFaleConosco(result.data[0].nomeFaleConosco)
      setEmailFaleConosco(result.data[0].emailFaleConosco)
      setAssunto(result.data[0].assunto)
      setMensagem(result.data[0].mensagem)
      setAd_new(result.data[0].ad_new)
      setUsuario_id(result.data[0].usuario_id)

      //console.log(result.data[0])

    }).catch((err) => {
      console.log(err.response);
    });
  };



  useEffect(() => {

    carregaMensagem()

  }, [faleConosco_id])


  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div data-collapsed="0" className="card">
            <div className="card-header">
              <div className="card-title">
                <h2 className="card-title">Fale Conosco</h2>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="formGroupExampleInput2">Nome: *</label>
                    <input type="text" className="form-control" value={nomeFaleConosco} disabled />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="formGroupExampleInput3">E-mail: *</label>
                    <input type="text" className="form-control" value={emailFaleConosco} disabled />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="formGroupExampleInput3">Assunto: *</label>
                    <input type="text" className="form-control" value={assunto} disabled />
                  </div>
                </div>


                {/*<div className="col-lg-6">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="formGroupExampleInput4">Assunto: *</label>
                    <select className="form-control" value={assunto} disabled>
                      <option value="">[Selecione]</option>
                      <option value="Pré-cadastro">Pré-cadastro</option>
                    </select>
                  </div>
                </div>*/}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="formGroupExampleInput7">Data de cadastro</label>
                    <input type="text" className="form-control" value={ad_new} disabled />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="MensagemFaleConosco">Mensagem: *</label>
                    <textarea
                      id="MensagemFaleConosco"
                      className="form-control"
                      value={mensagem}
                      disabled
                      rows="10"
                    />
                  </div>
                </div>

                <div className="col-lg-5">
                  <br />
                  <button
                    type="button"
                    className="btn btn-warning mt-2"
                    onClick={() => navigate('/MensagensFaleConosco')}
                  >
                    Voltar
                  </button>
                  &nbsp;&nbsp;

                  <button
                    type="button"
                    className="btn btn-success mt-2"
                    onClick={() => setShow(true)}
                  >
                    Responder
                  </button>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                      <Modal.Title>
                        <b>Resposta</b>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group">
                        <label htmlFor="assuntoModal">Assunto:</label>
                        <select
                          className="form-control"
                          value={assunto2}
                          onChange={(event) => setAssunto2(event.target.value)}
                          //disabled={true}
                        >
                          <option value="">[Selecione]</option>
                          <option value="Pré-cadastro">Pré-Cadastro</option>
                        </select>
                      </div>

                      <div className="mt-3">
                        <b>Escreva sua resposta:</b>
                        <textarea
                          className="form-control mt-2"
                          rows="5"
                          value={mensagemResposta}
                          onChange={(e) => setMensagemResposta(e.target.value)}
                          placeholder="Máximo 5.000 caracteres"
                          maxLength={5000}
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="warning" onClick={handleClose}>
                        Fechar
                      </Button>
                      <Button className="btn btn-success" onClick={faleConoscoResposta}>
                        Enviar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MensagemFaleConosco;